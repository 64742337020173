@import "../../Commons/variables.scss";

.app-owner-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

#app-mysites-header {
  margin-bottom: 15px;
}

.app-mysites-vanity-info {
  > p, > div {
    margin: 10px 0;
  }
}
.app-mysites-list-narrow {
  margin: 20px 0px;

  .app-mysites-list-narrow-row {
    padding: 15px;
  }
  
  .app-mysites-list-narrow-field {
    margin: 10px 0px;
  }
  
  .app-mysites-list-narrow-row.even {
    background: $app-steel;
  }
}



@media screen and (min-width: 1160px) {
  .app-mysites-list-narrow {
    display: none;
  }
}

@media screen and (max-width: 1159px) {
  .app-mysites-list-wide {
    display: none;
  }
}