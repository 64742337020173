@import "../../Commons/variables.scss";

.access-review-confirmation {
  padding: 20px 5%;
  background: $app-steel;
  position: sticky;
  top: 0;

  div.confirm-icon {
    height: 40px;
    float: left;
    font-size: 30px;
    line-height: 40px;
    margin: 0px 20px;
  }

  div.confirm-text {
    h3 {
      font-size: 20px;
    }
  }
}

@media screen and (min-width: 1300px) {
  .access-review-wrapper {
    padding-top: 20px;
    display: grid;
    grid-template-columns: 1fr 10px 2fr;
  
    #access-review-info-wrapper {
      position: sticky;
      top: 10px;
      height:fit-content;
      grid-column: 1;
    }
  
    #access-review-categories {
      grid-column: 3;
    }

    #access-review-info-wrapper.completed {
      top: 100px;
    }
  }
  
  
}


@media screen and (max-width: 1299px) {
  .access-review-wrapper {
    // height: 100%;
    padding-top: 20px;
  
    #access-review-info-wrapper {
    }
  
    #access-review-categories {
      margin-top: 20px;
    }
  }
  
  
}
