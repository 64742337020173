@import "../../Commons/variables.scss";

.ar-task {
  width: 250px;
  padding: 10px;
  display: grid;
  grid-template-rows: 24px 1fr 20px 20px 20px 50px;

  .ar-task-header {
    display: flex;

    i {
      font-size: 24px;
    }

    h3 {
      font-size: 20px;
      padding: 0px 10px;
    }
  }

  a {
    display: block;
  }

  .spgov-task-button {
    margin-top: 20px;
  }
}
