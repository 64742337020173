@import "../../Commons/variables.scss";

.app-main-header {
  grid-row: 1;
  grid-column: 1;
  height: 70px;

  -ms-grid-row: 1;
  -ms-grid-column: 1;

  background: $app-white;
}
.app-main-header.transparent {
  background: none;
}

.app-main-name {
  line-height: 60px;
  margin-left: 10px;
}

.app-main-header {
  display: -ms-grid;
  display: grid;

  -ms-grid-rows: 1fr;
  -ms-grid-columns: 1fr 200px 1fr;

  grid-template-rows: 1fr;
  grid-template-columns: 1fr 200px 1fr;

  .app-main-navigation .app-main-name {
    grid-row: 1;
    grid-column: 2;

    -ms-grid-row: 1;
    -ms-grid-column: 2;
  }

  .app-header-toolbar {
    grid-row: 1;
    grid-column: 3;

    -ms-grid-row: 1;
    -ms-grid-column: 3;
  }
}

.spgov-logo-wrapper {
  height: 70px;
  grid-row: 1;
  grid-column: 2;

  -ms-grid-row: 1;
  -ms-grid-column: 2;

  a {
    display: block;
    height: 70px;
  }

  img {
    height: 70px;
    margin: 0px auto;
  }
}
