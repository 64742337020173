@import "../../Commons/variables.scss";

.app-progressbar-wrapper {
  padding: 0px 20px;
  margin-top: 20px;
  min-height: 20px;

  .app-progressbar {
    width: 250px;
    height: 20px;
    margin-top: 5px;
    background: $app-steel;
    border-radius: 10px;

    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;

    > div {
      flex-grow: 1;
    }

    .step-completed {
      background: $app-blue;
    }
    .last-completed-step {
      border-radius: 0px 10px 10px 0px;
    }
  }
}


.app-progressbar-wrapper.completed {
  .app-progressbar-completed {
    float: left;
    margin-right: 8px;
    height: 20px;

    i {
      font-size: 20px;
    }
  }
  
  h3 {
    float: left;
  }
}
