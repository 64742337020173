@import "../../Commons/variables.scss";

.ar-category {
  .category-description {
    padding: 20px 20px 0px 20px;
    margin: 0;
  }

  .category-tasks-header {
    display: block;
    min-height: 25px;
    padding: 20px;

    h3 {
      float: left;
    }
    .category-tasks-toggle:hover {
      cursor: pointer;
    }
    .category-tasks-toggle {
      float: right;
      display: flex;
      color: $app-blue;

      span {
        padding-right: 15px;
      }
      > div > i {
        padding-top: 4px;
      }
    }
  }
  .category-tasks {
    display: flex;
    flex-wrap: wrap;

    padding: 0px 30px;
  }
}