@import "../../Commons/variables.scss";


.app-vanity-form-customizations {
  .app-vanity-form-is-required {
    padding: 10px 0px;
    font-style: italic;
  }

  div.app-vanity-form-additional-info {
    padding: 10px 20px 10px 0px;

    textarea#app-vanity-form-additional-info-textarea {
      resize: vertical;
      width: 100%;
      display: block;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
    }
  }

  .app-vanity-form-customizations-row {
    padding: 10px 0px;
    line-height: 20px;

    .app-vanity-form-helptext {
      font-style: italic;
      margin: 5px 10px;
    }

    .app-vanity-form-infobox {
      margin-top: 15px;
    }

    .app-vanity-form-customizations-option {
      padding: 5px 10px;
    }

    input[type="radio"] {
      border: 0px;
      height: 18px;
      width: 18px;
      margin: 0px;
      vertical-align: middle;
    }

    label {
      padding: 5px;
      vertical-align: middle;
    }
  }
  .app-vanity-form-customizations-row.hide {
    display: none;
  }
}

