div.app-sitecollection-info {


  div.app-sitecollection-info-row {
    display: -ms-grid;
    display: grid;
    margin: 5px 0px;
  
    -ms-grid-rows: 1fr;
    -ms-grid-columns: 1fr 1fr;
  
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }

    li.app-sitecollection-info-teamslink {
      display: flex;
      
      a {
        line-height: 20px;
      }
      
      img {
        height: 16px;
        width: 16px;
        padding: 3px 5px 0px 0px;
      }
    }

  }
}