/* VOLVOCARS COLORS */
$app-steel: #F0F0F0;
$app-medium-grey: #999999;

$app-blue: #3D5881;
$app-black: #101423;
$app-white: #fff;

$app-status-red: #FF5B49;
$app-status-yellow: #FFE552;
$app-status-green: #42CC5F;


