@import "../../Commons/variables.scss";

.spgov-dwp-button {
  max-width: 200px;
  padding: 20px;
  background-color: $app-white;
  box-shadow: inset 0px 0px 0px 2px $app-black;
  color: $app-black;
  border: none;

  h3 {
    font-weight: 500;
  }
}

.spgov-dwp-button.disabled,
.spgov-dwp-button.disabled.isPrimary,
.spgov-dwp-button.disabled:hover,
.spgov-dwp-button.disabled.isPrimary:hover  {
  background: $app-steel;
  cursor: default;
  box-shadow: none;

  h3 {
    color: $app-medium-grey;
  }
}

.spgov-dwp-button:hover {
  background-color: $app-black;
  cursor: pointer;
  
  h3 {
    color: $app-white;
  }
}

.spgov-dwp-button.isPrimary {
  background-color: $app-black;
  box-shadow: none;

  h3 {
    color: $app-white;
  }
}

/* --------------------------------------------------- */

.spgov-standard-button {
  text-align: center;
  line-height: 50px;
  height: 50px;
  background-color: $app-white;
  box-shadow: inset 0px 0px 0px 2px $app-black;
  color: $app-black;
  text-transform: uppercase;
  font-style: normal;
  font-weight: 500;
  display: inline-block;

  > span {
    font-family: "Volvo Novum Medium";
    font-size: 13px;
    padding: 0px 30px;
  }
}

.spgov-standard-button:hover {
  color: $app-white;
  background-color: $app-black;
  cursor: pointer;
  
  > span {
    color: $app-white;
  }
}

.spgov-standard-button.isPrimary {
  color: $app-white;
  background-color: $app-black;

  > span {
    color: $app-white;
  }
}

.spgov-standard-button.disabled {
  background: $app-steel;
  cursor: default;
  box-shadow: none;

  > span {
    color: $app-medium-grey;
  }
}

.spgov-standard-button.disabled:hover {
  background: $app-steel;
  
  > span {
    color: $app-medium-grey;
  }
}

.spgov-standard-button-wrapper.padded {
  padding: 20px;
  margin: 0px auto;
}


.spgov-standard-button-wrapper.centered {
  padding: 20px;
  margin: 0px auto;
}

.spgov-standard-button.loading,
.spgov-standard-button.loading:hover {
  background: none;
  cursor: default;
  line-height: 20px;
  .ms-Spinner-label {
    margin: 0;
  }
}

.spgov-standard-button.completed:hover {
  cursor: default;
  background: $app-steel;
  color: $app-black;
  border: none;
  cursor: default;
  height: 50px;
}

.spgov-standard-button.completed {
  display: -ms-grid;
  display: grid;
  line-height: 20px;

  -ms-grid-rows: 1fr;
  -ms-grid-columns: 1fr 4fr 1fr;

  grid-template-rows: 1fr;
  grid-template-columns: 1fr 4fr 1fr;

  background: $app-steel;
  color: $app-black;

  > div {
    grid-row: 1;
    grid-column: 2;

    -ms-grid-row: 1;
    -ms-grid-column: 2;
  }

  i {
    text-align: right;
    padding-right: 5px;
    line-height: 50px;
    font-size: 22px;

    grid-row: 1;
    grid-column: 1;

    -ms-grid-row: 1;
    -ms-grid-column: 1;
  }

  h3 {
    line-height: 50px;
  }

  h3.text {
    padding-top: 12px;
    line-height: 12px;
  }
  .subtext {
    line-height: 12px;
    font-size: 12px;
  }
}
