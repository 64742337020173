@import "../../Commons/variables.scss";

.mygroups-links {
  table{
    a{
      text-decoration: none;
    }
  
    td {
      padding: 5px 40px 5px 0px;
    }
    padding-bottom: 40px;
  }
}

.groupLink{
    text-decoration: none;
}

.groupDescriptionWrapped{
  white-space: normal;
}

.app-owner-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

#app-mygroups-header {
  margin-bottom: 15px;
}

.app-mygroups-vanity-info {
  > p, > div {
    margin: 10px 0;
  }
}
.app-mygroups-list-narrow {
  margin: 20px 0px;

  .app-mygroups-list-narrow-row {
    padding: 15px;
  }
  
  .app-mygroups-list-narrow-field {
    margin: 10px 0px;
  }
  
  .app-mygroups-list-narrow-row.even {
    background: $app-steel;
  }

  a{
    text-decoration: none;
  }
}

@media screen and (min-width: 1160px) {
  .app-mygroups-list-narrow {
    display: none;
  }
}

@media screen and (max-width: 1159px) {
  .app-mygroups-list-wide {
    display: none;
  }
}
