@import "../../Commons/variables.scss";

.app-vanity-uat-header {
    height: 70px;
    display: flex;
    justify-content: space-between;

    .ms-SearchBox {
        width: 40%;
    }
}