@import '../../Commons/variables.scss';

.app-main-appbar {
  height: 50px;
  background-color: $app-black;
  
  i, span {
    color: white;
    font-size: 14px;
  }
}

@media screen and (min-width: 1100px) {
  .app-main-appbar {
    display: -ms-grid;
    display: grid;
  
    -ms-grid-rows: 1fr;
    -ms-grid-columns: 250px 1fr 250px;
  
    grid-template-rows: 1fr;
    grid-template-columns: 250px 1fr 250px;

    .appbar-dwp {
      grid-row: 1;
      grid-column: 1;

      -ms-grid-row: 1;
      -ms-grid-column: 1;
    }

    .app-header-toolbar {
      grid-row: 1;
      grid-column: 3;

      -ms-grid-row: 1;
      -ms-grid-column: 3;
    }
  }
}

@media screen and (max-width: 1099px) {
  .app-main-appbar {
    display: -ms-grid;
    display: grid;
  
    -ms-grid-rows: 1fr;
    -ms-grid-columns: 250px 1fr 250px;
  
    grid-template-rows: 1fr;
    grid-template-columns: 250px 1fr 250px;

    .appbar-dwp {
      grid-row: 1;
      grid-column: 1;

      -ms-grid-row: 1;
      -ms-grid-column: 1;
    }

    .app-header-toolbar {
      grid-row: 1;
      grid-column: 3;

      -ms-grid-row: 1;
      -ms-grid-column: 3;
    }
  }
}