@import "../../Commons/variables.scss";

.spgov-site-exp-info-buttons {
  display: -ms-grid;
  display: grid;

  -ms-grid-columns: 1fr 20px 1fr;

  grid-template-columns: 1fr 20px 1fr;

  margin: 25px auto;
  max-width: 420px;

  .spgov-site-exp-info-cancel {
    grid-row: 1;
    grid-column: 3;

    -ms-grid-row: 1;
    -ms-grid-column: 3;
  }
}

.spgov-site-exp-info-title {
  text-align: center;

  span {
    padding: 5px;
    font-size: 22px;

    a {
      color: $app-blue;
      text-decoration: none;
    }
  }
}
