@import "../../Commons/variables.scss";

.spgov-site-exp-info-success {
  margin: 0px auto;
  max-width: 420px;
  padding: 5px;
  text-align: center;
  
  i {
    color: $app-blue;
    font-size: 40px;
  }

  h2 {
    padding: 15px;
  }
  
  p {
    margin: 0;
    text-align: left;
  }
}
