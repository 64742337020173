@import "../../Commons/variables.scss";

#access-review-info-wrapper {
  #access-review-info {
    padding: 10px 20px;
    margin-bottom: 10px;

    p {
      margin: 10px 0px;
    }
  }

  a {
    display: block;
  }
  #access-review-site-info {
    overflow: hidden;

    table { 
      padding: 20px;
      
      .site-info-key {
        width: 22%;
      }
    }
  }
  .access-review-reminder {
    padding: 0px 20px;
    padding-top: 5px;
    
    span {
      font-style: italic;
      font-size: 14px;
    }
  }
}