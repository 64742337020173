@import '../../Commons/variables.scss';

.app-header-toolbar {
    height: 50px;
    width: 40%;
    position: absolute;
    right: 5px;
}

.app-header-toolbar input {
    width: 200px;
    height: 32px;
    margin: 0px auto;
}

.app-header-toolbar-item {
    height: 50px;
    float: right;
    margin: 0px 10px;
    line-height: 50px;
    font-size: 20px;
}

.app-header-toolbar-item > div.dropdown {
    vertical-align: middle;
}

.app-header-toolbar-item-multiline {
    height: 50px;
    float: right;
    margin: 0px 10px;
    line-height: 14px;
}

.app-header-toolbar-item-multiline h4 {
    font-size: 14px;
    margin: 0;
}

.app-header-toolbar-item-multiline p, h4 {
    font-size: 14px;
    margin: 0;
}

.app-header-toolbar-usertext {
    line-height: 50px;
}

i.icon.app-header-toolbar-user {
    margin: 0px auto;
}