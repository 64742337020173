@import "../../Components/Commons/variables.scss";

@font-face {
	font-family: 'Volvo Novum Bold';
	src: url('../../../public/fonts/Volvo\ Novum-Bold.eot'); /* IE9 Compat Modes */
	src: url('../../../public/fonts/Volvo\ Novum-Bold.woff2') format('woff2'), /* Super Modern Browsers */
	url('../../../public/fonts/Volvo\ Novum-Bold.woff') format('woff'), /* Pretty Modern Browsers */
	url('../../../public/fonts/Volvo\ Novum-Bold.svg') format('svg'); /* Legacy iOS */
}

@font-face {
	font-family: 'Volvo Novum Light';
	src: url('../../../public/fonts/Volvo\ Novum-Light.eot'); /* IE9 Compat Modes */
	src: url('../../../public/fonts/Volvo\ Novum-Light.woff2') format('woff2'), /* Super Modern Browsers */
	url('../../../public/fonts/Volvo\ Novum-Light.woff') format('woff'), /* Pretty Modern Browsers */
	url('../../../public/fonts/Volvo\ Novum-Light.svg') format('svg'); /* Legacy iOS */
}
@font-face {
	font-family: 'Volvo Novum Medium';
	src: url('../../../public/fonts/Volvo\ Novum-Medium.eot'); /* IE9 Compat Modes */
	src: url('../../../public/fonts/Volvo\ Novum-Medium.woff2') format('woff2'), /* Super Modern Browsers */
	url('../../../public/fonts/Volvo\ Novum-Medium.woff') format('woff'), /* Pretty Modern Browsers */
	url('../../../public/fonts/Volvo\ Novum-Medium.svg') format('svg'); /* Legacy iOS */
}
@font-face {
	font-family: 'Volvo Novum';
	src: url('../../../public/fonts/Volvo\ Novum-Regular.eot'); /* IE9 Compat Modes */
	src: url('../../../public/fonts/Volvo\ Novum-Regular.woff2') format('woff2'), /* Super Modern Browsers */
	url('../../../public/fonts/Volvo\ Novum-Regular.woff') format('woff'), /* Pretty Modern Browsers */
	url('../../../public/fonts/Volvo\ Novum-Regular.svg') format('svg'); /* Legacy iOS */
}

* {
  font-family: "Volvo Novum", Verdana, Geneva, Tahoma, sans-serif;
  font-size: 16px;
}

.spgov-bold {
  font-family: "Volvo Novum Medium";
}

html,
body,
div#root,
div#app {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background: $app-white;
}

div.app-page-wrapper {
  position: absolute;
  top: 120px;
  right: 0;
  left: 0;
  bottom: 0;

  div.app-page-content {
    width: 70%;
    margin: 0px auto;
    padding-top: 30px;
  }

  div.app-page-content.wide {
    width: 90%;
    margin: 0px auto;
    padding-top: 30px;
  }
}

h1,
h2,
h3,
h4,
h5,
p {
  margin: 0;
  color: $app-black;
}
h3 {
  font-size: 22px;
  font-weight: 500;
  font-family: "Volvo Novum Medium";

  a {
    font-size: 22px;
    text-decoration: none;
    font-family: "Volvo Novum Medium";
    color: $app-blue;
  }
}
h4 {
  font-size: 16px;
  font-weight: 500;
  font-family: "Volvo Novum Medium";
}
h5 {
  font-size: 14px;
  font-weight: 500;
  font-family: "Volvo Novum Medium";
}


a {
  text-decoration: underline;
  color: $app-blue;
}

p.italics {
  font-style: italic;
}

.spgov-page-appbar,
.spgov-page {
  height: 100%;
}

.loading-wrapper {
  padding-top: 50px;
}

.app-global-error {
  position: absolute;
  z-index: 2;
  top: 50px;
}

.ms-CommandBar {
  background: $app-steel !important;

  button {
    background: $app-steel !important;
  }
  button:hover {
    background: $app-steel !important;
  }
}

.app-info-center {
  width: 600px;
  margin: 0px auto;

  h3 {
    text-align: center;
    margin-bottom: 10px;
  }
}

.app-right-button-wrapper {
  height: 50px;
  margin: 20px;

  > div {
    margin-left: 10px;
    float: right;
  }
}

